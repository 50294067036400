import React, { Component } from 'react';
import ReactGA from 'react-ga';

import Routes from './Routes';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

import brandsData from './assets/brandsData';
import linksData from './assets/linksData';
import aboutData from './assets/aboutData';
import tailoredData from './assets/tailoredData';
import colorsData from './assets/colorsData';
import './assets/colorsData.css';

class App extends Component {
  static defaultProps = {
    colors: colorsData,
    links: linksData,
    about: aboutData,
    tailored: tailoredData,
    brands: brandsData,
    googleAnalytics: 'UA-122756774-1'
  };

  state = {
    favorites: JSON.parse(window.localStorage.getItem('brandFavorites')) || []
  };

  componentDidMount() {
    ReactGA.initialize(this.props.googleAnalytics);
  }

  gaPageView = (fullPathName) => {
    ReactGA.pageview(fullPathName);
  };

  toggleFavorite = (brandName, favoriteExist) => {
    const favoritesData =
      JSON.parse(window.localStorage.getItem('brandFavorites')) || [];

    let changedFavorites;
    if (favoriteExist) {
      changedFavorites = favoritesData.filter((b) => b.name !== brandName);
    } else {
      changedFavorites = [...favoritesData, { name: brandName }];
    }

    window.localStorage.setItem(
      'brandFavorites',
      JSON.stringify(changedFavorites)
    );

    this.setState({ favorites: changedFavorites });
  };

  clearFavorites = () => {
    window.localStorage.setItem('brandFavorites', JSON.stringify([]));
    this.setState({ favorites: [] });
  };

  render() {
    return (
      <div className='App'>
        <Header
          {...this.state}
          colors={this.props.colors}
          about={this.props.about}
        />
        <Routes
          {...this.state}
          {...this.props}
          gaPageView={this.gaPageView}
          toggleFavorite={this.toggleFavorite}
          clearFavorites={this.clearFavorites}
        />
        <Footer colors={this.props.colors} about={this.props.about} />
      </div>
    );
  }
}

export default App;
