const tailored = {
  title: 'Custom-made? You are at the right place!',
  subTitle: `Don't waste your time on creating a brand. Validate your business idea by launching fast. Let us create the right brand for you.`,
  price: '1590',
  service:
    'We will create a brand pack that fit your business idea. We’ll take care of the design process so that you can focus on launching your business.',
  process: [
    'Tell us about your business idea in a brief.',
    'We will research brand names that fit you and offer three options.',
    'You will choose one of the provided options.',
    'We will create for you an entire brand pack.',
  ],
  result: [
    'Business Name',
    'Domain Name',
    'Brand Logo',
    'Brand Favicon',
    'Business Card Design',
  ]
};

export default tailored;