// const colors = {
//   base: '#d8345f',
//   dark: '#ba2b50',
//   copy: '#a13f58',
//   heart: '#d8345f',
//   heartHead: '#a13f58',
// };

const colorsBrandeets = {
  base: '#343a40',
  dark: '#ba2b50',
  copy: '#40454b',
  heart: 'red',
  heartHead: 'red',
};

export default colorsBrandeets;