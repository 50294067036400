import React from 'react';
import './Tailored.css';

const Tailored = props => {
  return (
    <div className='album py-5 bg-light'>
      <div className='container'>
        <div>
          <h3>{props.tailored && props.tailored.title}</h3>
          <p>{props.tailored && props.tailored.subTitle}</p>

          <h5>The Service:</h5>
          <div className='features-main'>
            <div style={{ marginLeft: '1rem' }}>
              {props.tailored && props.tailored.service}
            </div>
          </div>

          <h5>The Process:</h5>
          <div className='features-main'>
            <ol style={{ margin: '0' }}>
              {props.tailored &&
                props.tailored.process.map((p, i) => <li key={i}>{p}</li>)}
            </ol>
          </div>

          <h5>The Result:</h5>
          <div className='features-main'>
            <ul style={{ margin: '0' }}>
              {props.tailored &&
                props.tailored.result.map((r, i) => <li key={i}>{r}</li>)}
            </ul>
          </div>

          <div style={{ textAlign: 'center' }}>
            <h4 style={{ margin: '1.5rem', fontWeight: '700' }}>
              Price: ${props.tailored && props.tailored.price}
            </h4>
            <div className='row'>
              <div className='col-md-3'></div>
              <div className='col-md-6'>
                <a
                  className='btn btn-dark contactNow'
                  href={props.about && 'mailto:' + props.about.mailTailored}
                >
                  Contact us
                </a>
              </div>
              <div className='col-md-3'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tailored;
