import React from 'react';

const BrandTags = props => {
  return (
    <div style={{ textAlign: 'center', margin: '1rem' }}>
      Tags:
      <div className='row'>
        {props.relatedBrand.tags.map((tag, i) => (
          <div
            key={tag + i}
            className='col-lg-6 col-md-12 col-sm-4 col-xs-4 tag-space'
          >
            <span className='tag'>{tag}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandTags;