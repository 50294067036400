import React from 'react';

const PriceBox = (props) => {
  return (
    <div className='priceBox'>
      <p style={{ fontSize: '2rem', fontWeight: '500' }}>
        Price:{' '}
        {props.relatedBrand.price
          ? '$' + props.relatedBrand.price
          : 'Negotiable'}
      </p>
      {props.relatedBrand.buyLink && (
        <button
          className='buyNow btn btn-dark'
          onClick={props.handleOpenWindow}
        >
          <i
            style={{ color: '#00a116' }}
            className='fa fa-check'
            aria-hidden='true'
          />{' '}
          Buy now
        </button>
      )}
      <a
        className={
          'buyNow btn ' +
          (props.relatedBrand.buyLink ? 'btn-light' : 'btn-dark')
        }
        href={
          props.about &&
          `mailto:${props.about.mailSales}?Subject=${props.relatedBrand.name}`
        }
      >
        Contact us
      </a>
      <div style={{ marginTop: '1rem' }}>
        <i
          style={{ color: '#00a116' }}
          className='fa fa-check'
          aria-hidden='true'
        />{' '}
        Instant & Secure Transaction
        <br />
      </div>
    </div>
  );
};

export default PriceBox;