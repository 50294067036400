import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './Header.module.css';
import logoPath from '../../assets/logo.png';

const Header = props => {
  return (
    <header style={{ marginBottom: '5rem' }}>
      {/* <div className='navbar navbar-dark bg-dark box-shadow fixed-top'> */}
      <div
        style={{ background: props.colors.base }}
        className='navbar navbar-dark box-shadow fixed-top'
      >
        <div className='container d-flex justify-content-between'>
          <Link
            id={Styles.toCenter}
            to='/'
            className='navbar-brand d-flex align-items-center'
          >
            <strong>
              <img
                style={{ width: '150px' }}
                src={logoPath}
                alt={props.about && props.about.name}
              />
              <div style={{ paddingBottom: '0.5rem' }} />
            </strong>
          </Link>
          {props.favorites && props.favorites.length > 0 && (
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  top: '-12px',
                  right: '0',
                }}
              >
                <Link to='/favorites'>
                  <i
                    style={{
                      cursor: 'pointer',
                      color: props.colors.heartHead,
                      fontSize: '150%',
                    }}
                    className='fa fa-heart'
                    title='Favorites'
                  ></i>
                  <span
                    style={{
                      position: 'absolute',
                      top: '3px',
                      right: props.favorites.length < 10 ? '9px' : '7px',
                      fontSize: '60%',
                      color: 'white',
                    }}
                  >
                    {props.favorites.length <= 99 ? props.favorites.length : 99}
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
