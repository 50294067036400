import React from 'react';
import Brand from './Brand/Brand';
import './BrandList.css';

const BrandList = props => {
  return (
    <div className='album py-5 bg-light'>
      <div className='container'>
        <div className='row'>
          {props.brands.map((b) => (
            <Brand
              brand={b}
              key={b.name}
              detailsBtn={props.about.detailsBtn}
              imageInfo={props.about.imageInfo}
              colors={props.colors}
              favorites={props.favorites}
              toggleFavorite={props.toggleFavorite}
            />
          ))}
        </div>
      </div>

      <div
        className='text-muted'
        style={{ textAlign: 'center', marginTop: '2rem' }}
      >
        {props.about && props.about.disclaimer}
      </div>
    </div>
  );
};

export default BrandList;
