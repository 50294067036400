import React from 'react';

const WhatBizDeets = props => {
  return (
    <div style={{ margin: '1.7rem 0 0 0' }}>
      <div style={{ textAlign: 'left', margin: '1rem' }}>
        <h5>What You Get:</h5>
        <div className='card-text'>
          {props.about && props.about.whatYouGet}
        </div>
      </div>

      <div style={{ textAlign: 'left', margin: '1rem' }}>
        <h5>Business Ideas:</h5>
        <ul className='card-text'>
          {props.relatedBrand.ideas.map((idea) => (
            <li key={idea}>{idea}</li>
          ))}
        </ul>
      </div>

      <div style={{ textAlign: 'left', margin: '1rem' }}>
        <h5>The Deets:</h5>
        {props.relatedBrand.infos.map((info) => (
          <div key={info} className='card-text'>
            {info}
          </div>
        ))}
      </div>

      <div
        className='text-muted'
        style={{ textAlign: 'center', marginBottom: '2rem' }}
      >
        {props.about && props.about.disclaimer2}
      </div>
    </div>
  );
};

export default WhatBizDeets;