import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './Footer.module.css';

const Footer = props => {
  const icons = [
    { name: 'visa', class: 'fa fa-cc-visa' },
    { name: 'masterCa', class: 'fa fa-cc-mastercard' },
    { name: 'americanEx', class: 'fa fa-cc-amex' },
    { name: 'discover', class: 'fa fa-cc-discover' },
    { name: 'paypal', class: 'fa fa-cc-paypal' }
  ];

  return (
    <div>
      <div
        className='text-muted'
        style={{ textAlign: 'center', margin: '2rem' }}
      >
        <div style={{ fontSize: '150%' }}>
          <i className='fa fa-lock' aria-hidden='true' />{' '}
          {props.about && props.about.secured}
        </div>
        <div style={{ fontSize: '300%', color: '#6c757d' }}>
          {icons.map((i) => (
            <i
              key={i.name}
              style={{ marginRight: '1rem' }}
              className={i.class}
              aria-hidden='true'
            />
          ))}
        </div>
      </div>

      <footer
        style={{ background: props.colors.base }}
        className={'footer text-center ' + Styles.footerStyle}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h4 style={{ textTransform: 'uppercase' }}>Contact</h4>
              <a
                className={
                  'btn btn-outline-light btn-social text-center ' +
                  Styles.noBorder
                }
                href={props.about && 'mailto:' + props.about.mailInfo}
              >
                {props.about && props.about.mailInfo}
              </a>
            </div>

            <div className='col-md-4'>
              <h4 style={{ textTransform: 'uppercase' }}>Legal</h4>
              <div>
                <ul className='list-inline'>
                  <li className='list-inline-item'>
                    <Link
                      className={
                        'btn btn-outline-light btn-social text-center ' +
                        Styles.noBorder
                      }
                      to='/legal/terms'
                    >
                      Terms
                    </Link>
                  </li>
                  <li className='list-inline-item'>
                    <Link
                      className={
                        'btn btn-outline-light btn-social text-center ' +
                        Styles.noBorder
                      }
                      to='/legal/privacy'
                    >
                      Privacy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-md-4'>
              <h4 style={{ textTransform: 'uppercase' }}>About</h4>
              <p
                style={{
                  textAlign: 'left',
                  marginBottom: '0',
                  marginTop: '1rem',
                }}
              >
                {props.about && props.about.about}
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div
        style={{ background: props.colors.copy }}
        className={Styles.copyBrand}
      >
        {props.about && props.about.name}&copy; All rights reserved. Powered by{' '}
        <a href='https://olofame.com' id={Styles.poweredBy}>
          olo<span id={Styles.fame}>fame</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;