import React from 'react';

const BrandCard = props => {
  const favoriteExist = !!props.favorites.find(
    b => b.name === props.relatedBrand.name
  );

  const handleFavorite = () => {
    props.toggleFavorite(props.relatedBrand.name, favoriteExist);
  }
 
  return (
    <div className='card mb-4 box-shadow'>
      <img
        style={{ position: 'relative' }}
        className='card-img-top image'
        src={props.relatedBrand.logoSrc}
        alt={props.relatedBrand.name}
      />
      <p
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          color: '#007f8c',
          userSelect: 'none',
          padding: '0 5px 0 5px',
          borderRadius: '5px',
          backgroundColor: 'rgb(248,249,250, 0.5)',
        }}
      >
        {props.relatedBrand.badge && props.relatedBrand.badge}
      </p>
      <span
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <i
          onClick={handleFavorite}
          style={{
            cursor: 'pointer',
            color: favoriteExist ? props.colors.heart : '#c0c6ce',
          }}
          className={favoriteExist ? 'fa fa-heart' : 'fa fa-heart-o'}
          title={favoriteExist ? 'UnFavorite' : 'Favorite'}
        ></i>
      </span>
      <div className='card-body'>
        <p
          style={{ textAlign: 'center', fontWeight: '700' }}
          className='card-text'
        >
          {props.relatedBrand.name}
        </p>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='btn-group'>
            <button
              onClick={props.handleBackClick}
              className='btn btn-sm btn-light'
            >
              {props.detailsBtn && props.detailsBtn}
            </button>
          </div>
          <small className='text-muted'>
            {props.relatedBrand.price
              ? '$' + props.relatedBrand.price
              : 'Negotiable'}
          </small>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;