import React from 'react';
import BrandList from '../Ext/BrandList/BrandList';
import { NavLink, withRouter } from 'react-router-dom';

const Favorites = (props) => {
  if (!props.favorites || props.favorites.length <= 0) {
    setTimeout(() => {
      props.history.push('/');
      return null;
    }, 3000);
  }

  let brandExist = true;
  const favoritesData = props.favorites.map(f => {
    const brand = props.brands.find(b => b.name === f.name);
    if (!brand) {
      brandExist = false;
      props.clearFavorites();
    }
    return brand;
  })

  // document.title = 'BRANDEETS - Domain Brands - Favorites';
  document.title =
    props.about &&
    props.about.name + ' - ' + props.about.service + ' - Favorites';

  return (
    <main role='main'>
      <section className='text-center'>
        <div className='container'>
          <h1
            style={{ color: props.colors.base }}
            className='jumbotron-heading'
          >
            FAVORITES
          </h1>
          <p style={{ color: props.colors.copy }} className='lead'>
            {props.about && props.about.subTitle}
          </p>
          <p>
            {props.links.map((l) => (
              <NavLink
                key={l.link}
                exact
                to={l.link}
                style={{ margin: '2px', fontWeight: '700' }}
                className='btn btn-light'
                activeClassName='btn-dark'
              >
                {l.name}
              </NavLink>
            ))}
          </p>
        </div>
      </section>

      {brandExist && (
        <BrandList
          brands={favoritesData}
          colors={props.colors}
          about={props.about}
          favorites={props.favorites}
          toggleFavorite={props.toggleFavorite}
        />
      )}
    </main>
  );
};

export default withRouter(Favorites);
