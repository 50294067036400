import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = props => {
  window.scroll(0, 0);
  document.title = props.about && props.about.name + ' - Privacy Policy';
  
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Privacy Policy</h3>

      <p style={{ textAlign: 'center' }} className='text-muted'>
        {props.about && props.about.name}©
      </p>

      <div
        style={{
          paddingTop: '2rem',
          paddingBottom: '2rem',
          backgroundColor: '#f8f9fa',
        }}
      >
        <div className='container'>
          <p>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            {props.about && ' ' + props.about.domain} (the “Site”).
          </p>

          <h5>PERSONAL INFORMATION WE COLLECT</h5>
          <p>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information.”
          </p>

          <p>
            We collect Device Information using the following technologies:{' '}
            <br />
            - “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org. <br />
            - “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps. <br />- “Web beacons,”
            “tags,” and “pixels” are electronic files used to record information
            about how you browse the Site.
          </p>

          <p>
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information.
          </p>

          <h5>HOW DO WE USE YOUR PERSONAL INFORMATION?</h5>

          <p>
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </p>

          <h5>SHARING YOUR PERSONAL INFORMATION</h5>
          <p>
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above. We also use Google
            Analytics to help us understand how our customers use the Site--you
            can read more about how Google uses your Personal Information here:
            https://www.google.com/intl/en/policies/privacy/. You can also
            opt-out of Google Analytics here:
            https://tools.google.com/dlpage/gaoptout.
          </p>

          <p>
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </p>

          <h5>YOUR RIGHTS</h5>
          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
          </p>

          <p>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </p>

          <h5>TRANSACTIONS</h5>
          <p>
            Transactions are made by the systems of Porkbun LLC, Namecheap Inc,
            Namesilo LLC, Epik Inc and Sedo.com LLC.
          </p>

          <h5>THIRD-PARTY LINKS</h5>
          <p>
            Third-party legal information is accessible at the following links:{' '}
            <br />
            https://porkbun.com/legal <br />
            https://porkbun.com/legal/agreement/product_terms_of_service <br />
            https://www.namecheap.com/legal/ <br />
            https://www.namesilo.com/Support/Policies <br />
            https://policies.google.com/ <br />
            https://www.google.com/analytics/terms/us.html <br />
            https://sedo.com/us/about-us/policies/ <br />
            https://sedo.com/us/about-us/policies/terms-of-use/ <br />
            https://www.epik.com/terms.php <br />
          </p>

          <h5>MINORS</h5>
          <p>The Site is not intended for minors.</p>

          <h5>CHANGES</h5>
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>

          <h5>TERMS OF SERVICE</h5>
          <p>
            Terms of service of {props.about && props.about.name}© is accessible
            at the following link: <br />
            https://{props.about && props.about.domain}/legal/terms
          </p>

          <h5>CONTACT US</h5>
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at {props.about && props.about.mailInfo}
          </p>

          <Link className='btn btn-sm btn-light' to='/'>
            Go back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Privacy;