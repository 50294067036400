import React from 'react';
import { withRouter } from 'react-router-dom';
import PriceBox from './PriceBox/PriceBox';
import WhatBizDeets from './WhatBizDeets/WhatBizDeets';
import BrandCard from './BrandCard/BrandCard';
import BrandTags from './BrandTags/BrandTags';
import './Brand.css';

const Brand = props => {
  window.scroll(0, 0);
  const { name } = props.match.params;
  const relatedBrand = props.brands.find(
    b => b.name.toLowerCase() === name.toLowerCase()
  );
  if (relatedBrand === undefined) {
    props.history.push('/');
    return null;
  }
  // document.title = relatedBrand.name + ' is for sale - BRANDEETS - Domain Brands';
  document.title =
    props.about &&
    relatedBrand.name +
      ' is for sale - ' +
      props.about.name +
      ' - ' +
      props.about.service;

  const handleBackClick = () => {
    props.history.push('/dot/' + relatedBrand.ext);
  };

  const handleOpenWindow = () => {
    window.open(
      relatedBrand.buyLink,
      '_blank',
      'location=yes,height=690,width=650,scrollbars=yes,status=yes'
    );
  };

  return (
    <main role='main'>
      <section className='text-center'>
        <div style={{ marginBottom: '1.5rem' }}>
          <span className='text-muted'>The domain name</span>
          <br />
          <span
            style={{ fontSize: '2rem', fontWeight: '500' }}
            className='jumbotron-heading'
          >
            <a href={'http://' + relatedBrand.name}>{relatedBrand.name}</a>
          </span>
          <span className='text-muted'> is for sale</span>
        </div>

        <div style={{ paddingTop: '3rem', backgroundColor: '#f8f9fa' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <div className='container'>
                  {
                    <PriceBox
                      relatedBrand={relatedBrand}
                      about={props.about}
                      handleOpenWindow={handleOpenWindow}
                    />
                  }
                </div>
                {
                  <WhatBizDeets
                    relatedBrand={relatedBrand}
                    about={props.about}
                  />
                }
              </div>

              <div className='col-md-1'></div>

              <div className='col-md-4'>
                {
                  <BrandCard
                    relatedBrand={relatedBrand}
                    detailsBtn={props.about.detailsBtn2}
                    colors={props.colors}
                    favorites={props.favorites}
                    toggleFavorite={props.toggleFavorite}
                    handleBackClick={handleBackClick}
                  />
                }
                {<BrandTags relatedBrand={relatedBrand} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default withRouter(Brand);
