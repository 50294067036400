import React from 'react';
import BrandList from './BrandList/BrandList';
import Tailored from './Tailored/Tailored';
import { NavLink, withRouter } from 'react-router-dom';

const Ext = props => {
  // window.scroll(0, 0);
  const { ext } = props.match.params;
  const relatedBrands = props.brands.filter(
    b => b.ext.toLowerCase() === ext.toLowerCase()
  );
  if (relatedBrands.length === 0 && ext !== 'tailored') {
    props.history.push('/');
    return null;
  }

  let extTitle = '';
  if (ext === 'not') {
    extTitle = 'Not com domains';
  } else if (ext === 'tailored') {
    extTitle = 'Tailored';
  } else {
    extTitle = 'Dot ' + ext + ' domains';
  }
  // document.title = 'BRANDEETS - Domain Brands - ' + extTitle + ' for sale';
  document.title =
    props.about &&
    props.about.name +
      ' - ' +
      props.about.service +
      ' - ' +
      extTitle +
      ' for sale';

  return (
    <main role='main'>
      <section className='text-center'>
        <div className='container'>
          <h1
            style={{ color: props.colors.base }}
            className='jumbotron-heading'
          >
            {props.about && props.about.title}
          </h1>
          <p style={{ color: props.colors.copy }} className='lead'>
            {props.about && props.about.subTitle}
          </p>
          <p>
            {props.links.map((l) => (
              <NavLink
                key={l.link}
                exact
                to={l.link}
                style={{ margin: '2px', fontWeight: '700' }}
                className='btn btn-light'
                activeClassName='btn-dark'
              >
                {l.name}
              </NavLink>
            ))}
          </p>
        </div>
      </section>

      {ext !== 'tailored' ? (
        <BrandList
          brands={relatedBrands}
          colors={props.colors}
          about={props.about}
          favorites={props.favorites}
          toggleFavorite={props.toggleFavorite}
        />
      ) : (
        <Tailored tailored={props.tailored} about={props.about} />
      )}
    </main>
  );
};

export default withRouter(Ext);
