import React, { useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import Ext from './Components/Ext/Ext';
import Brand from './Components/Brand/Brand';
import Favorites from './Components/Favorites/Favorites';
import Terms from './Components/Legal/Terms/Terms';
import Privacy from './Components/Legal/Privacy/Privacy';
import NotFound from './Components/NotFound/NotFound';

const Routes = props => {
  useEffect(() => {
    handleGaPageView();
  });

  const handleGaPageView = () => {
    props.gaPageView(
      props.location.pathname + props.location.search + props.location.hash
    );
  }

  return (
    <Switch>
      <Route exact path='/dot/:ext' render={() => <Ext {...props} />} />
      <Route exact path='/brand/:name' render={() => <Brand {...props} />} />
      <Route exact path='/favorites' render={() => <Favorites {...props} />} />
      <Route exact path='/legal/terms' render={() => <Terms about={props.about} />} />
      <Route exact path='/legal/privacy' render={() => <Privacy about={props.about} />} />
      <Route path='/:anything' render={() => <NotFound />} />
      <Redirect to='/dot/com' />
    </Switch>
  );
};

export default withRouter(Routes);