import React from 'react';
import { withRouter, Link } from 'react-router-dom';

const NotFound = props => {
  setTimeout(() => {
    props.history.push('/');
  }, 3000);
  return (
    <div className='container'>
      <h3>Page Not Found</h3>
      <p>There is nothing here :(</p>

      <Link to='/' className='btn btn-sm btn-outline-secondary'>
        Go back
      </Link>
      <br />
      <br />
    </div>
  );
};

export default withRouter(NotFound);
