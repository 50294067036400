const about = {
  name: 'BRANDEETS',
  domain: 'brandeets.com',
  mailInfo: 'info@brandeets.com',
  mailSales: 'sales@brandeets.com',
  mailTailored: 'tailored@brandeets.com',
  service: 'Domain Brands',
  detailsBtn: 'Deets',
  detailsBtn2: 'Go back',
  title: 'CHOOSE THE BRAND',
  subTitle: 'An entire brand pack at a competitive price!',
  disclaimer:
    'All logos shown are for illustration purposes only. We will create a custom logo that fits your brand.',
  disclaimer2:
    'The logo shown is for illustration purposes only. We will create a custom logo that fits your brand.',
  imageInfo: 'Complete with a domain, logo, favicon and business card design!',
  whatYouGet:
    'Creative business name, domain, logo, favicon and business card design!',
  secured: 'Transactions are secured by SSL encryption.',
  about: `We provide a unique branding service for your company. Our mission is to make a difference with your brand image by using your vision to tell the story.`,
};

export default about;
